@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
.time {
  font-size: 32px;
  font-weight: 600;
}

.time-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-wrapper > div:last-child {
  font-size: 14px;
  margin-top: 4px;
}
